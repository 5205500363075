
import { copySelectTextContents, MoDate } from '@mobro/libs';
import { Iobject } from '@mobro/libs/es/type';
import { request, IRequestMethod } from '@mobro/request';
import { Message } from 'element-ui';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ReadTodayCheckList extends Vue {
    private checkItemList: Iobject[] = [];
    private showPeriodPrizerModal = false;
    private formData = {
        activityId: '',
        readCycle: MoDate.format('YYYYMM', Date.now()),
    };

    private activityList: Iobject[] = [];
    private lock = 0;
    private periodPrizerList: Iobject = [];

    created() {
        this.getActitvityList();
    }

    onSearch() {
        this.getCheckItemList();
    }
    getActitvityList() {
        request({
            url: '/api/activity/list',
            params: {
                activityType: 2,
            },
        }).then(res => {
            this.activityList = res.data || [];
        });
    }

    getCheckItemList() {
        request({
            url: '/api/common/read/check/list',
            method: IRequestMethod.POST,
            params: {
                current: 1,
                pageSize: 999,
                readCycle: this.readCycle,
                activityId: this.formData.activityId,
                checkOk: 1,
            },
        }).then(res => {
            this.checkItemList = (res.data || []).map((s: Iobject) => {
                return {
                    nickName: s.nickName,
                    avatarUrl: s.avatarUrl,
                    uuid: s.uuid,
                    selected: false,
                };
            });
            this.lock = 1;
        });
    }

    async onPrize(force = false) {
        const periodPrizerList: Iobject[] = await this.getPeriodPrizerList();
        const exist = periodPrizerList.find(
            (s: Iobject) => s.readCycle === this.readCycle && s.activityId === this.formData.activityId,
        );

        if (!force && exist) {
            this.checkItemList = this.checkItemList.map((s: Iobject) => {
                if (s.uuid === exist.uuid) {
                    s.selected = true;
                }
                return s;
            });
            // this.lock = 2;
            return;
        }

        const len = this.checkItemList.length;
        const index = Math.floor(Math.random() * len);
        let prizer = {};

        if (this.checkItemList.length <= 0) return;

        // checkItemList 为满足条件的打卡者
        this.checkItemList = this.checkItemList.map((s: Iobject, idx: number) => {
            if (index === idx) {
                s.selected = true;
                prizer = s;
            }
            return s;
        });
        // this.lock = 2;
        this.uploadPrizerResult(prizer);
    }

    async uploadPrizerResult(prizer: Iobject) {
        request({
            url: '/api/common/read/prizer/update',
            method: IRequestMethod.POST,
            params: {
                readCycle: this.readCycle,
                activityId: this.formData.activityId,
                uuid: prizer.uuid,
            },
        }).then(res => {
            console.log(res, 'ssssssss');
        });
    }

    getPeriodPrizerList() {
        return request({
            url: '/api/common/read/prizer/list',
            method: IRequestMethod.GET,
            data: {},
        }).then(res => {
            console.log(res, '往期中奖');
            this.periodPrizerList = res || [];
            return res;
        });
    }

    async onShowPeriodPrizer() {
        await this.getPeriodPrizerList();

        this.periodPrizerList.length > 0 && (this.showPeriodPrizerModal = true);
    }

    async onCopyUserInfo(user: Iobject) {
        const text = `昵称：${user.nickName}
        uuid：${user.uuid}
        头像：${user.avatarUrl}`;
        await copySelectTextContents(text);
        Message.success('复制成功！');
    }

    get periodPrizerListTmp() {
        return this.periodPrizerList.map((p: Iobject) => {
            const x = this.checkItemList.find(s => s.uuid === p.uuid) || {};
            return {
                ...p,
                ...x,
            };
        });
    }

    get getCreatePrize() {
        return this.lock < 1;
    }

    get readCycle() {
        return this.activityList?.find((a: Iobject) => a.activityId === this?.formData?.activityId)?.extra?.readCycle;
    }
}
